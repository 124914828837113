.header-left {
    display: flex;
    align-items: center; /* Align the image and text vertically */
}

.profile-pic {
    width: 50px; /* Adjust the size as needed */
    height: 50px; /* Ensure the image is square */
    border-radius: 50%; /* Makes the image circular */
    margin-right: 0.5em; /* Adds a 2-character space between image and text */
}

.text-container {
    display: flex;
    flex-direction: column; /* Stack the name and title vertically */
    margin: 0; /* Remove any margin on the text container */
    padding: 0; /* Remove any padding on the text container */
}

.home-link {
    text-decoration: none;
    color: inherit;
    margin: 0; /* Remove any margin */
    padding: 0; /* Remove any padding */
    display: flex; /* Ensure the link behaves correctly in the flex container */
    align-items: center; /* Align the text and icon */
}

.home-link h1 {
    margin: 0;
    padding: 0;
    color: #00CEC5; /* Default color */
    line-height: 1; /* Remove extra space above and below text */
    display: inline-block;
}

.home-link h1:hover,
.home-link h1:focus,
.home-link h1:active {
    color: #00CEC5;
    text-decoration: underline;
}

.linkedin-icon {
    margin-left: 8px; /* Small space between the name and the LinkedIn icon */
    color: #00CEC5; /* Same color as the name */
    font-size: 24px; /* Adjust icon size */
    vertical-align: middle;
}

.linkedin-icon:hover {
    color: #008f8d; /* Darken on hover */
}

.header-left h2 {
    margin-top: 2px; /* Control spacing between name and title */
    font-size: 16px; /* Adjust font size if necessary */
    color: #666; /* Optional: Change color for the title */
    line-height: 1; /* Remove extra space above and below text */
}
