.App {
    text-align: center;
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #000;
    color: #fff;
}

.header-left h1 {
    margin: 0;
    font-size: 24px;
}

.header-left h2 {
    margin: 0;
    font-size: 18px;
    color: #ccc;
}

.header-right nav a {
    margin: 0 15px;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    transition: color 0.3s;
}

.header-right nav a:hover {
    color: #ffa500;
}

.main-content {
    max-width: 900px;
    margin: 50px auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.profile-image img {
    width: 100%;
    max-width: 350px;
    border-radius: 50%;
}

.profile-summary {
    flex: 1;
    padding: 20px;
}

.profile-buttons button {
    margin: 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #ffa500;
    border: none;
    color: #fff;
    transition: background-color 0.3s;
}

.profile-buttons button:hover {
    background-color: #e69500;
}

.footer {
    margin-top: 50px;
    padding: 20px;
    background-color: #000;
    color: #fff;
}

.footer a {
    margin: 0 10px;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    transition: color 0.3s;
}

.footer a:hover {
    color: #ffa500;
}
