.resume-page {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    color: #333;
}

.experience-section,
.education-section {
    margin-bottom: 40px;
}

.experience-section h2,
.education-section h2 {
    background-color: #000;
    color: #fff;
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
}

.experience-item,
.education-item {
    display: flex;
    margin-bottom: 20px;
}

.experience-timeline,
.education-timeline {
    flex: 0 0 20%; /* Adjust this percentage to make the timeline column narrower */
    font-weight: bold;
    padding-right: 20px;
    text-align: left; /* Ensure text is left-aligned */
}

.experience-timeline h3,
.education-timeline h3 {
    display: flex;
    flex-direction: column;
    margin: 0;
    line-height: 1.2;
}

.experience-details,
.education-details {
    flex: 1;
    text-align: left; /* Ensure everything in the details section is left-aligned */
}

.experience-details h4,
.education-details h4 {
    margin: 5px 0;
    font-style: italic;
    text-align: left; /* Ensure headers are left-aligned */
}

.experience-details ul,
.education-details ul {
    padding-left: 20px;
    list-style-type: disc;
    margin: 0;
    text-align: left; /* Ensure bullet points are left-aligned */
}

.experience-details li,
.education-details li {
    margin-bottom: 10px;
}

.education-details p {
    margin: 0;
    text-align: left; /* Ensure text is left-aligned */
}
