.profile-buttons {
    display: flex;
    gap: 10px; /* Adjust the space between buttons as needed */
}

.button-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: #00CEC5; /* Button background color */
    color: #fff; /* Button text color */
    text-decoration: none;
    border-radius: 5px;
    text-align: center;
    transition: background-color 0.3s;
}

.button-link:hover {
    background-color: #008f8d; /* Darker color on hover */
}
