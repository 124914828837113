.projects-page {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.projects-container {
    display: flex;
    flex-direction: row;
}

.projects-nav {
    width: 20%;
    margin-right: 20px;
    text-align: left; /* Ensure left alignment */
}

.projects-nav ul {
    list-style-type: none;
    padding: 0;
    text-align: left; /* Ensure left alignment */
}

.projects-nav li {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ccc;
    margin-bottom: 5px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    text-align: left; /* Ensure left alignment */
}

.projects-nav li:hover {
    background-color: #f0f0f0;
}

.projects-nav li.active {
    background-color: #e0e0e0;
    font-weight: bold;
}

.project-details {
    width: 80%;
    text-align: left; /* Ensure left alignment */
}

.project-details h2, 
.project-details h3, 
.project-details p, 
.project-details ul {
    text-align: left; /* Ensure left alignment */
}

.project-details h2 {
    margin-top: 0;
}
